<template>
  <div>
    <div class="d-flex mb-8">
      <div class="titulo-pagina">
        {{ tituloFormulario }}
      </div>
    </div>

    <v-form ref="form" class="row">
      <input-text
        v-model="form.nome"
        class="col-12 col-md-6"
        :label="$t('modulos.grandeza_fisica.formulario.nome')"
        :max="50"
        obrigatorio
      />
      <input-text
        v-model="form.descricao"
        class="col-12 col-md-6"
        :label="$t('modulos.grandeza_fisica.formulario.descricao')"
        :max="250"
      />
    </v-form>

    <v-divider class="my-5" />
    <div class="d-flex align-center justify-end">
      <botao-padrao outlined color="secondary" class="mr-2" @click="cancelar">
        <v-icon>$mdiCancel</v-icon>
        {{ $t('geral.botoes.cancelar') }}
      </botao-padrao>
      <botao-padrao
        :disabled="!valido"
        :tooltip="tooltipBotaoSalvar"
        @click="confirmacaoSalvar"
      >
        <v-icon>$mdiContentSaveOutline</v-icon>
        {{ $t('geral.botoes.salvar') }}
      </botao-padrao>
    </div>
  </div>
</template>
<script>
import GrandezaFisicaService from '@common/services/cadastros/GrandezaFisicaService.js';
import { GrandezaFisicaModel } from '@common/models/cadastros/GrandezaFisicaModel.js';
import helpers from '@common/utils/helpers';
export default {
  props: ['id'],
  data() {
    return {
      valido: false,
      form: new GrandezaFisicaModel({}),
    };
  },
  computed: {
    tituloFormulario: function () {
      if (this.id) return this.$t('modulos.grandeza_fisica.titulos.editar');
      return this.$t('modulos.grandeza_fisica.titulos.novo');
    },
    tooltipBotaoSalvar: function () {
      if (this.valido) return '';
      return this.$t('modulos.grandeza_fisica.validacoes.formulario_invalido');
    },
  },
  watch: {
    form: {
      handler() {
        if (this.$refs.form) this.valido = this.$refs.form.validate();
      },
      deep: true,
    },
  },
  mounted() {
    if (this.id) {
      helpers.redirecionarSemPermissao(this,'GrandezaFisica', 'Editar');
      this.buscar(this.id);
    }
    helpers.redirecionarSemPermissao(this, 'GrandezaFisica', 'Inserir');
    this.$store.dispatch('Layout/alterarTituloPagina', this.tituloFormulario);
  },
  methods: {
    buscar: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      GrandezaFisicaService.buscar(this.id)
        .then((res) => {
          this.form = new GrandezaFisicaModel(res.data);
          this.valido = true;
        })
        .catch(() => {
          this.toastErro(this.$t('modulos.grandeza_fisica.erros.id_invalido'));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    confirmacaoSalvar: function () {
      if (this.id) {
        this.confirmarSalvar().then(() => {
          this.salvar();
        });
      } else this.salvar();
    },
    salvar: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      GrandezaFisicaService.salvar(this.form)
        .then(() => {
          this.toastSucesso(
            this.$t(`modulos.grandeza_fisica.cadastro_sucesso`)
          );
          this.$router.push({ name: 'grandeza-fisica' });
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    cancelar: function () {
      this.confirmarCancelar().then(() => {
        this.$router.push({ name: 'grandeza-fisica' });
      });
    },
  },
};
</script>
